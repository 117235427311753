import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useApolloClient } from '@apollo/client'
import { authTokenKey, domains, leadUserKey, emailKey } from '../config'

export function useLogout() {
  const client = useApolloClient()
  const router = useRouter()

  return useCallback(() => {
    router.push('/')
    sessionStorage.removeItem(authTokenKey)
    localStorage.removeItem(authTokenKey)

    client.resetStore()
  }, [client, router])
}

export function useLogin() {
  const client = useApolloClient()
  const router = useRouter()

  return useCallback(
    (
      token?: string | null,
      {
        redirectPath = domains.current.authenticatedLandingPath,
        expires = true,
      }: { redirectPath?: string | null; expires?: boolean } = {}
    ) => {
      if (!token) return

      sessionStorage.removeItem(leadUserKey)
      sessionStorage.removeItem(emailKey)

      const storage = expires ? sessionStorage : localStorage
      storage.setItem(authTokenKey, token)

      client.resetStore()

      if (redirectPath) {
        router.replace(redirectPath)
      }
    },
    [client, router]
  )
}

export function useUnauthorizedRedirect<Data extends { currentUser?: object | null } | undefined>(
  data: Data,
  loading: boolean
) {
  const router = useRouter()

  useEffect(() => {
    if (!loading && !data?.currentUser) {
      router.replace('/')
    }
  }, [loading, data])
}
