import { FC, HTMLAttributes } from 'react'
import tw from 'tailwind-styled-components'
import Link, { LinkProps } from 'next/link'
import { theme } from '../../config'

const colorsClassNameRegexp = new RegExp(
  Object.keys(theme.colors)
    .map((color) => `text-${color}`)
    .join('|')
)

export type Props = Pick<LinkProps, 'href'> &
  HTMLAttributes<HTMLAnchorElement> & {
    linkProps?: Omit<LinkProps, 'href'>
  }

const Anchor = tw.a`text-sm hover:underline cursor-pointer ${({ className }) =>
  className?.match(colorsClassNameRegexp) ? '' : 'text-primary-500'}`

export const NavLink: FC<Props> = ({ href, linkProps, ...props }) => (
  <Link {...linkProps} href={href}>
    <Anchor {...props} />
  </Link>
)
